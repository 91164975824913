<template>
    <div class="content-wrapper overflow-visible">        
        <div class="page-header page-header-sticky page-header-sticky-open">
            <div class="page-header-content page-header-dark mb-0 header-elements-md-inline">

                <div class="page-header-info flex-1 py-3 text-uppercase">
                    <div class="d-flex justify-content-between">
                        <div>
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 class="phi-main font-weight-semibold mb-2 pb-1">
                                    <span>{{rowReg.ap_fullname||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No Rekam Medis">{{rowReg.ap_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No Registrasi">{{rowReg.aur_reg_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No KTP">{{rowReg.ap_nik||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No SEP">{{rowReg.absd_no_sep||"-"}}</span>
                                </h5>
                            </div>
                            <div class="phi-sub-info d-flex">
                                <div class="phi-info d-flex flex-column">
                                    <small>Tanggal Lahir</small>
                                    <strong class="font-weight-semibold">{{rowReg.ap_dob | moment("DD MMM YYYY")}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Usia</small>
                                    <strong class="font-weight-semibold">{{rowReg.ap_usia_with_ket || "-"}}<br />({{rowReg.ap_gol_usia||"-"}})</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Jenis Kelamin</small>
                                    <strong class="font-weight-semibold">{{rowReg.cg_label}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Opsi Pembayaran</small>
                                    <strong class="font-weight-semibold">{{rowReg.mcp_name || "-"}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Agama</small>
                                    <strong class="font-weight-semibold">{{rowReg.ap_agama_text || "-"}}</strong>
                                </div>
                            </div>
                        </div>
                        
                        <div class="phi-info d-flex flex-column col-md-3 ml-auto">
                            <small>Dokter</small>
                            <h5 class="phi-main font-weight-semibold mb-0">
                                <span>{{rowReg.bu_full_name||"-"}}</span>
                            </h5>
                            <div class="mb-2">
                                <strong class="font-weight-semibold">{{rowReg.mpo_name||"-"}}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a href="javascript:;" class="btn btn-sm btn-primary page-header-sticky-toggle">
            <i class="icon-arrow-up5 align-middle"></i>
            <span class="align-middle">Sembunyikan Informasi Pasien</span>
            </a>
        </div>

        <div class="content">
            <div class="card mt-4">
            <div class="card-header bg-white">
                <h6 class="card-title font-weight-semibold">Detail Triase Pasien</h6>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6 col-lg-3 mb-2">
                        <h6>Perubahan Data</h6>
                        <v-select @input="doFillPerubahan($event)" placeholder=" -- Pilih Triase Pasien -- " v-model="triaseHistoryID"
                        :options="triaseLog" label="text" :clearable="true"
                        :reduce="v=>v.value">
                        </v-select>
                    </div>
                </div>
                <div class="wrap_line_heading">
                <div class="data_prev_cont">
                    
                    <!--
                    <div class="card-body bg_card_blue mb-3">
                    <div class="row">
                        <div class="col-md-9">
                        <table class="table table-borderless table-sm text-uppercase">
                            <tbody>
                                <tr>
                                    <td width="33%">
                                    <div class="result_tab">
                                        <h4>No. Reg</h4>
                                        <p>{{rowReg.aur_reg_code||"-"}}</p>
                                    </div>
                                    </td>
                                    <td width="33%">
                                    <div class="result_tab">
                                        <h4>No. Rekam Medis</h4>
                                        <p>{{rowReg.ap_code||"-"}}</p>
                                    </div>
                                    </td>
                                    <td width="33%">
                                    <div class="result_tab">
                                        <h4>Nama Pasien</h4>
                                        <p>{{rowReg.ap_fullname||"-"}}</p>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="33%">
                                    <div class="result_tab">
                                        <h4>Tempat, Tanggal Lahir </h4>
                                        <p>{{rowReg.ap_pob||"-"}}, {{rowReg.ap_dob | moment("DD MMMM YYYY")}}</p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Usia</h4>
                                        <p>{{rowReg.ap_usia_with_ket||"-"}}</p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Jenis Kelamin</h4>
                                        <p>{{rowReg.ap_gender == 1 ? 'Laki-Laki':'Perempuan'}}</p>
                                    </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                    </div>
                    -->
                    <table class="table table-bordered table-sm">
                    <tbody>
                        <tr>
                        <td width="33%">
                            <div class="result_tab">
                            <h4>Triase Pasien</h4>
                            <p>{{getConfigDynamic(mUGDTriase,row.aut_triase)||"-"}}</p>
                            </div>
                        </td>
                        <td width="33%">
                            <div class="result_tab">
                            <h4>Transportasi yang Digunakan</h4>
                            <p>{{getConfigDynamic(mUGDTransport,row.aut_transportasi)||"-"}}</p>
                            </div>
                        </td>
                        <td width="33%" v-if="rowReg.aures_surat_rujukan">
                            <div class="result_tab">
                            <h4>Surat Rujukan</h4>
                            <p><a :href="$parent.uploader(rowReg.aures_surat_rujukan)">Lihat Surat Rujukan</a></p>
                            </div>
                        </td>
                        </tr>
                        
                        <tr>
                        <td width="33%">
                            <div class="result_tab">
                            <h4>Penempatan</h4>
                            <p>{{row.aut_penempatan||"-"}}</p>
                            </div>
                        </td>
                        <td>
                            <div class="result_tab">
                            <h4>Skrining Kebutuhan Pasien</h4>
                            <p>{{row.aut_skrining_kebutuhan||"-"}}</p>
                            </div>
                        </td>
                        </tr>

                        <tr>
                        <td width="33%">
                            <div class="result_tab">
                            <h4>Nama Pengantar Pasien</h4>
                            <p>{{row.aut_pengantar_pasien||"-"}}</p>
                            </div>
                        </td>
                        <td>
                            <div class="result_tab">
                            <h4>No. Handphone</h4>
                            <p>{{row.aut_no_hp||"-"}}</p>
                            </div>
                        </td>
                        <td>
                            <div class="result_tab">
                            <h4>Waktu Triase</h4>
                            <p>{{row.aut_created_date | moment("DD MMMM YYYY, HH:mm")}} WIB</p>
                            </div>
                        </td>
                        </tr>
                        <tr class="table-secondary">
                        <th colspan="3">
                            <span class="font-weight-semibold">Keterangan Emergency Penjaminan</span>
                        </th>
                        </tr>
                        <tr>
                        <td>
                            <div class="result_tab">
                            <h4>Diagnosis</h4>
                            <p>{{row.aut_diagnosa||"-"}}</p>
                            </div>
                        </td>
                        <td>
                            <div class="result_tab">
                            <h4>Pasien Mendapatkan Surat Emergency?</h4>
                            <p>{{row.aut_is_surat_emergency == 'Y' ? 'Ya':'Tidak'}}</p>
                            </div>
                        </td>
                        <td>
                            <div class="result_tab">
                            <h4>Pasien Diarahkan Ke Rawat Inap</h4>
                            <p>{{row.aut_is_ranap == 'Y'?'Ya':"Tidak"}}</p>
                            </div>
                        </td>
                        </tr>
                        <template v-if="row.aut_is_ranap == 'Y'">
                        <tr>
                            <td>
                                <div class="result_tab">
                                <h4>Dokter</h4>
                                <p>{{row.bu_full_name||"-"}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="result_tab">
                                <h4>Ruangan</h4>
                                <p>{{row.mruan_name||"-"}}</p>
                                </div>
                            </td>
                        </tr>
                        </template>
                        <tr class="table-secondary">
                        <th colspan="3">
                            <span class="font-weight-semibold">Keterangan Perubahan</span>
                        </th>
                        </tr>
                        <tr>
                        <td colspan="3">
                            <div class="result_tab">
                            <h4>Notes</h4>
                            <p>{{row.aut_notes||"-"}}</p>
                            </div>
                        </td>
                        </tr>

                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="text-right">
                <a href="javascript:;" @click="$router.back()" class="btn btn-link">Kembali</a>
                </div>
            </div>
            </div>
            <!-- /dashboard content -->
        </div>
    </div>
</template>

<script>
    import $ from 'jquery'
    const _ = global._
    import Config from '@/libs/Config'
    import Gen from '@/libs/Gen.js'
    const moment = require('moment')
    
    export default{
        props:{
            row:Object,
            mrValidation: Object,
            rowPasien: Object,  
            rowReg: Object, 
            triaseLog: Array,
        },
        data(){
            return {
                mUGDTriase : [],
                mUGDTransport : [],
                triaseHistoryID: null
            }
        },
        mounted() {
            window.scrollTo(0,0)
            setTimeout(()=>{
                this.apiGetMaster()
            },250)
            
            setTimeout(()=>{
                this.initSticky()
            },1000)
        },
        computed:{
            Config(){ return Config },
        },    
        methods: {
            doFillPerubahan(e){
                if(e){
                    this.$parent.loadingOverlay = true
                    let data = {
                        id: e,
                        regId: this.$route.query.regId,
                        type: 'get-log'
                    }
                    Gen.apiRest(
                        "/do/" + 'UGDTriase', {
                            data: data
                        },
                    "POST"
                    ).then(res => {
                        this.$parent.loadingOverlay = false
                        _.forEach(res.data, (v,k)=>{
                            this.$set(this, k, v)
                        })
                    })
                }else{
                    this.$parent.apiGet()
                }
            },
            initSticky(){
                const PageHeaderSticky = document.querySelector('.page-header-sticky')
                if(PageHeaderSticky){    
                    const PageHeaderToggle = PageHeaderSticky.querySelector('.page-header-sticky-toggle')
                    if($(".page-header-sticky").length && $(".page-header-sticky-toggle").length){
                        PageHeaderToggle.addEventListener('click', function(e){
                        // e.preventDefault()
                        PageHeaderSticky.classList.toggle('page-header-sticky-open')
                        if (PageHeaderSticky.classList.contains('page-header-sticky-open')) {
                            PageHeaderToggle.querySelector('span').innerText = 'Sembunyikan Informasi Pasien'
                            PageHeaderToggle.querySelector('i').classList.add('icon-arrow-up5')
                            PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-down5')
                        }else{
                            PageHeaderToggle.querySelector('span').innerText = 'Tampilkan Informasi Pasien'
                            PageHeaderToggle.querySelector('i').classList.add('icon-arrow-down5')
                            PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-up5')
                        }
                        })    
                    } 
                }
            },

            apiGetMaster(){
                Gen.apiRest(
                    "/get/"+this.$parent.modulePage+'/master' 
                ).then(res=>{
                    _.forEach(res.data, (v,k)=>{
                        this.$set(this, k, v)
                    })
                })
            }, 
            getConfigDynamic(master, value) {
                let text = ''
                if (value) {
                    let index = (master||[]).findIndex(x => x.value == value)
                    if (index !== -1) {
                    text = master[index]['text']
                    }
                }
                return text
            }, 
        }
    }
</script>
        